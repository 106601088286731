import styled from "styled-components";

import { BasicButton } from "components/common/BasicButton";
import LogoutWrapper from "components/common/LogoutWrapper";
import { useTranslation } from "react-i18next";

type Props = {
    handler?: () => void;
};

const LogoutButton = ({ handler }: Props) => {
    const { i18n } = useTranslation();

    return (
        <LogoutWrapper>
            <LogOutBtn
                onClick={handler}
                dir={i18n.dir(i18n.language)}
            >{`${i18n.t("logOut")}`}</LogOutBtn>
        </LogoutWrapper>
    );
};

const LogOutBtn = styled(BasicButton)`
    padding: 0.25rem;
    font-size: 0.75rem;
    border: 1px solid #ccc;
    background-color: white;
    color: black;
    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
`;

export default LogoutButton;
