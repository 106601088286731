import { useEffect, useState } from "react";
import { buildGlobalStylesComponent } from "../globalStyles";
import { getSubdomain } from "services/urlService";
import { getCustomizationLocalStorageWithExpiry } from "services/customizationService";
import { CUSTOMIZATION_SET_EVENT } from "constants/constants";
import { useTranslation } from "react-i18next";

const GlobalStylesWrapper = () => {
    const subdomain = getSubdomain();
    const [currentTheme, setCurrentTheme] = useState<string | null>(
        getCustomizationLocalStorageWithExpiry()
    );
    const { i18n } = useTranslation();

    const textDirection = i18n.dir(i18n.language);
    const rtlNotistackOverrides = `
        .notistack-MuiContent {
            display: block;
        }
        
        .notistack-MuiContent #notistack-snackbar {
            text-align: end;
            display: block;
            margin-right: 1.5rem;
        }
    `;
    const notistackOverrides =
        "rtl" === textDirection ? rtlNotistackOverrides : null;

    useEffect(() => {
        if (subdomain && !currentTheme) {
            window.addEventListener(CUSTOMIZATION_SET_EVENT, () => {
                const customization = getCustomizationLocalStorageWithExpiry();
                if (customization) {
                    setCurrentTheme(customization);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GlobalStyle = buildGlobalStylesComponent({
        overrides: currentTheme,
        notistackOverrides,
    });

    return <GlobalStyle />;
};

export default GlobalStylesWrapper;
