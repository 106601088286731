import styled from "styled-components";
import { BaseOptionButton } from "./BaseOptionButton";

type Props = {
    text: string;
    isActive: boolean;
    isDisabled: boolean;
    onClick: () => void;
    color?: string;
};

interface StyledButtonProps {
    isActive: boolean;
}

const BasicCaseProposal = ({
    text,
    isActive,
    isDisabled,
    onClick,
    color,
}: Props) => {
    return (
        <StyledButton
            disabled={isDisabled}
            onClick={onClick}
            isActive={isActive}
            color={color}
            aria-label={`${text}-vote-basic`}
            data-testactive={isActive}
        >
            <StyledText>{text}</StyledText>
        </StyledButton>
    );
};

const StyledButton = styled(BaseOptionButton)<StyledButtonProps>`
    background: ${(props) =>
        props.isActive ? (props.color ? props.color : "#60b860") : null};
    color: ${(props) =>
        props.isActive ? "#ffffff " : `${props.color || "black"} `};
    border: ${(props) => `1px solid ${props.color}`};
    &[disabled] {
        background: ${(props) => (props.isActive ? props.color : "#e8e8e8")};
        opacity: 0.6;
        cursor: not-allowed;
    }
`;

const StyledText = styled.p`
    margin: auto !important;
`;

export default BasicCaseProposal;
