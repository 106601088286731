import { useState, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { VoteStatus } from "types/voteEnum";
import groupAndSortElectionProposals from "../services/groupAndSortElectionProposals";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import DistributionCaseProposal from "components/vote/Votebox/common/Buttons/DistributionCaseProposal";
import ActionSection from "components/vote/Votebox/common/ActionSection";

type Proposal = {
    disableOtherPropositionsIfSelected: boolean;
    id: string;
    text: string;
    isVisibleByDefault: boolean;
};

type Props = {
    propositions: Array<Proposal>;
    answers: Array<{
        id: string;
        weight: number;
    }>;
    isSecret: boolean;
    voterWeight: number;
    voteStatus: string;
    updateWeight: (id: string, weight: number) => void;
    validateVote: () => void;
    resetVote: () => void;
};

const VoteDistribution = ({
    propositions,
    answers,
    isSecret,
    voterWeight,
    voteStatus,
    updateWeight,
    validateVote,
    resetVote,
}: Props) => {
    const [shouldShowHiddenProposals, setShouldShowHiddenProposals] =
        useState(false);

    const { i18n } = useTranslation();

    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

    const remainingWeight = answers
        .map((answer) => answer.weight)
        .reduce(
            (previousValue, currentValue) => previousValue - currentValue,
            voterWeight
        );

    const renderToggleHiddenProposalsButton = () => {
        /* If one proposal is hidden, display the toggle */
        return propositions.some(
            (proposition) => !proposition.isVisibleByDefault
        ) ? (
            <WrapperToggle
                onClick={() =>
                    setShouldShowHiddenProposals(!shouldShowHiddenProposals)
                }
            >
                <MoreHorizIcon
                    style={{ fontSize: "20px", marginRight: "2px" }}
                />
                <StyledToggleText dir={i18n.dir(i18n.language)}>
                    {i18n.t(`seeMore.${process.env.REACT_APP_THEME}`)}
                </StyledToggleText>
            </WrapperToggle>
        ) : null;
    };

    const { visibleProposals, hiddenProposals } = groupAndSortElectionProposals(
        propositions.map((prop) => {
            const isActive =
                VoteStatus.Success === voteStatus && isSecret
                    ? false
                    : answers.some(
                          (answer) => answer.id === prop.id && answer.weight > 0
                      );

            return {
                ...prop,
                isActive,
            };
        })
    );

    const renderProposals = (
        proposals: Array<Proposal & { isActive: boolean }>
    ) => {
        const filteredInputRefs = inputRefs.current.filter(
            (ref) => ref !== null
        );

        const focusNextInput = (currentInputId: string) => {
            const currentProposal = propositions.find(
                (proposal) => proposal.id === currentInputId
            );

            if (!currentProposal) {
                return;
            }

            const indexOfCurrentProposal =
                propositions.indexOf(currentProposal);

            const nextInput = filteredInputRefs[
                indexOfCurrentProposal === filteredInputRefs.length - 1
                    ? 0
                    : indexOfCurrentProposal + 1
            ] as HTMLInputElement;

            nextInput.focus();
        };

        return proposals.map((proposition) => {
            const handleBlur = (inputValue: number) => {
                const currentAnswerWeight = answers.find(
                    (answer) => answer.id === proposition.id
                )?.weight as number;

                const newWeight =
                    inputValue >= remainingWeight + currentAnswerWeight
                        ? remainingWeight + currentAnswerWeight
                        : inputValue;

                updateWeight(proposition.id, newWeight);
            };

            const isDisabled = () => {
                if (VoteStatus.Success === voteStatus) {
                    return true;
                }

                if (proposition.isActive) {
                    return false;
                }

                if (0 === remainingWeight) {
                    return true;
                }

                return false;
            };

            return (
                <DistributionCaseProposal
                    key={proposition.id}
                    isActive={proposition.isActive}
                    text={proposition.text}
                    isDisabled={isDisabled()}
                    remainingWeight={remainingWeight}
                    onBlur={handleBlur}
                    value={
                        answers.find((answer) => answer.id === proposition.id)
                            ?.weight
                    }
                    onKeyUp={(e) => {
                        if ("Enter" === e.key) {
                            focusNextInput(proposition.id);
                        }
                    }}
                    currentRef={(el) =>
                        (inputRefs.current[Number(proposition.id)] = el)
                    }
                />
            );
        });
    };

    return (
        <>
            <NumberOfVoicesText dir={i18n.dir(i18n.language)}>
                {i18n.t("numberOfVote")} {remainingWeight}/{voterWeight}
            </NumberOfVoicesText>
            {renderProposals(visibleProposals)}
            {renderToggleHiddenProposalsButton()}
            {shouldShowHiddenProposals
                ? renderProposals(hiddenProposals)
                : null}
            <ActionSection
                validateVote={validateVote}
                resetVote={resetVote}
                voteStatus={voteStatus as VoteStatus}
                remainingWeight={remainingWeight}
            />
        </>
    );
};

const NumberOfVoicesText = styled.div`
    color: #626262;
    padding-bottom: 0.5rem;
    font-size: 14px;
`;

const WrapperToggle = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 0.5em;
`;

const StyledToggleText = styled.span`
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-left: 5px;
    font-weight: bold;
`;

export default VoteDistribution;
