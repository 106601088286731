import styled from "styled-components";
import { QuestionType as QuestionDataType } from "types/dataVoteType";
import { QuestionType } from "types/questionTypes";
import { convertLineBreak } from "services/convertLineBreak";

import { useTranslation } from "react-i18next";

type Props = {
    question: QuestionDataType;
};

const VoteHeader = ({ question }: Props) => {
    const { i18n } = useTranslation();

    const renderNumberOfAnswersToSelect = () => {
        if (question.minNumberOfAnswers === question.maxNumberOfAnswers) {
            return (
                <span dir={i18n.dir(i18n.language)}>
                    {i18n.t("youMustSelectMinimumOfAnswers", {
                        min: question.maxNumberOfAnswers,
                    })}
                </span>
            );
        }
        return (
            <span dir={i18n.dir(i18n.language)}>
                {i18n.t("youMustMustSelectBetweenMinAndMaxAnswers", {
                    min: question.minNumberOfAnswers,
                    max: question.maxNumberOfAnswers,
                })}
            </span>
        );
    };

    return (
        <Wrapper>
            <div>{convertLineBreak(question.title)}</div>
            <VoteType dir={i18n.dir(i18n.language)}>
                {question.isSecret
                    ? i18n.t("secretVote")
                    : i18n.t("publicVote")}
            </VoteType>
            {question.type === QuestionType.ELECTION ? (
                1 === question.numberOfSeats ? (
                    <Styled dir={i18n.dir(i18n.language)}>
                        {i18n.t("uninominalElection")}
                    </Styled>
                ) : (
                    <>
                        <Styled dir={i18n.dir(i18n.language)}>
                            {i18n.t("plurinominalElection")}
                        </Styled>
                        <Styled>{renderNumberOfAnswersToSelect()}</Styled>
                    </>
                )
            ) : null}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    font-size: 1.2rem;
    background-color: #f5f5f5;
    font-size: 14px;
    padding: 0.75rem;
    margin-bottom: 1rem;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
`;

const VoteType = styled.div`
    font-weight: bold;
    padding-top: 0.5rem;
    color: #626262;
    font-weight: bold;
`;

const Styled = styled.div`
    padding-top: 0.5rem;
    color: #626262;
    font-weight: bold;
`;

export default VoteHeader;
