import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { VoteStatus } from "types/voteEnum";

import { ValidateButton } from "components/common/ValidateButton";
import { Input } from "components/common/Input";
import { Alert } from "components/common/Alert";
import { requestEmailPassword } from "components/helper/callApi";

type Props = {
    onlineCode: string;
    onSubmitSuccess: (email: string) => void;
    onSubmitFail: () => void;
};

const EmailForm = ({ onSubmitSuccess, onlineCode, onSubmitFail }: Props) => {
    const { i18n } = useTranslation();
    const emailRef = useRef<HTMLInputElement>(null);

    const [status, setStatus] = useState(VoteStatus.Idle);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!emailRef.current) {
            return;
        }

        setStatus(VoteStatus.PendingValidation);

        const email = emailRef.current.value;

        requestEmailPassword({ email, codeOnline: onlineCode })
            .then((res) => {
                if (res.status !== 200) {
                    throw res.status;
                }

                onSubmitSuccess(email);

                setStatus(VoteStatus.Idle);
            })
            .catch((err) => {
                onSubmitFail();

                if (typeof err !== "number") {
                    setErrorMessage(`${i18n.t("passwordEmailError")}`);
                    setStatus(VoteStatus.Error);
                    return;
                }

                const responseStatus = err;

                switch (responseStatus) {
                    case 404:
                        setErrorMessage(
                            `${i18n.t("passwordEmailerrorSessionNotFound")}`
                        );
                        break;
                    case 403:
                        setErrorMessage(
                            `${i18n.t(
                                "passwordEmailErrorRequestedTooRecently"
                            )}`
                        );
                        break;
                    case 409:
                        setErrorMessage(
                            `${i18n.t("passwordEmailErrorScenarioIncorrect")}`
                        );
                        break;
                    default:
                        setErrorMessage(`${i18n.t("passwordEmailError")}`);
                }

                setStatus(VoteStatus.Error);
            });
    };
    return (
        <form method="post" className="form panel-body" onSubmit={handleSubmit}>
            {"error" === status && errorMessage && (
                <AlertError>{errorMessage}</AlertError>
            )}
            <div className="form-group">
                <StyledInput
                    type="email"
                    className="form-control"
                    name="email"
                    ref={emailRef}
                    autoComplete="off"
                    placeholder={`${i18n.t("email")} (*)`}
                    aria-label={i18n.t("email")}
                    dir={i18n.dir(i18n.language)}
                    required
                />
            </div>
            <StyledButton
                type="submit"
                className="btn btn-primary btn-full"
                data-join-in-text={i18n.t("joinIn")}
                disabled={VoteStatus.PendingValidation === status}
                dir={i18n.dir(i18n.language)}
            >
                {`${i18n.t("requestPassword")}`}
            </StyledButton>
        </form>
    );
};

const StyledInput = styled(Input)``;

const StyledButton = styled(ValidateButton)``;

const AlertError = styled(Alert)``;

export default EmailForm;
