import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { VoteStatus } from "../Deliberation/useVoteState";

type Answer = {
    id: string;
    weight: number;
    disableOtherPropositionsIfSelected: boolean;
};

type Props = {
    participantIndex: number;
    participant: { displayName: string; weight: number };
    voteIsWithDistribution: boolean;
    participantVote: {
        voteStatus: string;
        answers: Array<Answer>;
    };
    isGroupedDeliberation?: boolean;
};

const ParticipantHeader = ({
    participantIndex,
    participant,
    voteIsWithDistribution,
    participantVote,
    isGroupedDeliberation,
}: Props) => {
    const { i18n } = useTranslation();

    const renderWeightSection = () => {
        const remainingWeight = participantVote.answers
            .map((answer) => answer.weight)
            .reduce(
                (previousValue, currentValue) => previousValue - currentValue,
                participant.weight
            );

        return (
            <>
                <StyledNumberOfVoices dir={i18n.dir(i18n.language)}>
                    {i18n.t("numberOfVote")}
                    {voteIsWithDistribution ? `${remainingWeight}/` : null}
                    {participant.weight}
                </StyledNumberOfVoices>
                {voteIsWithDistribution && !isGroupedDeliberation ? (
                    participantVote.voteStatus === VoteStatus.Success ? (
                        <StyledSuccessVotedMessage
                            dir={i18n.dir(i18n.language)}
                        >
                            {i18n.t(
                                `sentSucceded.${process.env.REACT_APP_THEME}`
                            )}
                        </StyledSuccessVotedMessage>
                    ) : null
                ) : null}
            </>
        );
    };

    return (
        <>
            <WrapperName>
                {participantIndex > 0 && (
                    <StyledNameWrapper>
                        <StyledName dir={i18n.dir(i18n.language)}>
                            {i18n.t("proxy")}:
                        </StyledName>
                        <StyledName>{participant.displayName}</StyledName>
                    </StyledNameWrapper>
                )}
            </WrapperName>

            {participant.weight > 1 ? (
                renderWeightSection()
            ) : participantVote.voteStatus === VoteStatus.Success ? (
                <StyledSuccessVotedMessage dir={i18n.dir(i18n.language)}>
                    {i18n.t(`sentSucceded.${process.env.REACT_APP_THEME}`)}
                </StyledSuccessVotedMessage>
            ) : null}
        </>
    );
};

const WrapperName = styled.div`
    display: flex;
    font-size: 14px;
`;

const StyledNameWrapper = styled.div`
    display: flex;
`;

const StyledName = styled.p`
    margin-bottom: 0;
    font-weight: 600;
    margin-right: 5px;
`;

const StyledNumberOfVoices = styled.div`
    color: #626262;
    padding-bottom: 0.5rem;
    font-size: 14px;
`;

const StyledSuccessVotedMessage = styled.p`
    color: #60b860;
    margin: 0;
    font-size: 0.85rem;
`;

export default ParticipantHeader;
