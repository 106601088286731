export enum QuestionType {
    UNDEFINED = "UNDEFINED",
    UNIQUE_CHOICE = "UNIQUE_CHOICE",
    NUMERICAL = "NUMERICAL",
    NOTE = "NOTE",
    GROUPED = "GROUPED",
    TEAMS = "TEAMS",
    CLASSIFICATION = "CLASSIFICATION",
    NUMERICAL_CORRECT_ANSWER = "NUMERICAL_CORRECT_ANSWER",
    LIST = "LIST",
    MULTIPLE_CHOICE_CORRECT_ANSWER = "MULTIPLE_CHOICE_CORRECT_ANSWER",
    UNIQUE_CHOICE_CORRECT_ANSWER = "UNIQUE_CHOICE_CORRECT_ANSWER",
    MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
    ORDERED_ANSWERS = "ORDERED_ANSWERS",
    CORRECT_ORDERED_ANSWERS = "CORRECT_ORDERED_ANSWERS",
    DELIBERATION = "DELIBERATION",
    ELECTION = "ELECTION",
    ROLL_CALL = "ROLL_CALL",
    ANONYMOUS_ROLL_CALL = "ANONYMOUS_ROLL_CALL",
    ID_ROLL_CALL = "ID_ROLL_CALL",
    TEST = "TEST",
    GROUPED_EVALUATION = "GROUPED_EVALUATIONS",
    // Activities will begin at 1000 to clearly separate questions / activities.
    TAG_CLOUD = "TAG_CLOUD",
}
