import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { VoteStatus } from "types/voteEnum";

import { ValidateButton } from "components/common/ValidateButton";
import { Input } from "components/common/Input";
import { Alert } from "components/common/Alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type Props = {
    successMessage?: string;
    handleSubmit: (email: string, password: string) => Promise<void>;
    defaultEmailValue?: string;
};

const EmailPasswordForm = ({
    successMessage,
    handleSubmit,
    defaultEmailValue,
}: Props) => {
    const { i18n } = useTranslation();

    const [status, setStatus] = useState(VoteStatus.Idle);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState(false);

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (emailRef.current && defaultEmailValue) {
            emailRef.current.value = defaultEmailValue;
        }
    }, [defaultEmailValue]);

    return (
        <form
            method="post"
            className="form panel-body"
            onSubmit={(event) => {
                event.preventDefault();

                if (!emailRef.current || !passwordRef.current) {
                    return;
                }

                setStatus(VoteStatus.PendingValidation);

                const emailValue = emailRef.current.value;
                const passwordValue = passwordRef.current.value;

                handleSubmit(emailValue, passwordValue).catch(
                    (errorMessageParam) => {
                        setErrorMessage(errorMessageParam);
                        setStatus(VoteStatus.Error);
                    }
                );
            }}
        >
            {errorMessage && <AlertError>{errorMessage}</AlertError>}
            {successMessage && !errorMessage && (
                <AlertSuccess>{successMessage}</AlertSuccess>
            )}
            <div className="form-group">
                <StyledInput
                    ref={emailRef}
                    type="email"
                    className="form-control"
                    name="email"
                    autoComplete="off"
                    placeholder={`${i18n.t("email")} (*)`}
                    aria-label={i18n.t("email")}
                    dir={i18n.dir(i18n.language)}
                    required
                />
            </div>
            <div className="form-group">
                <StyledInput
                    ref={passwordRef}
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    name="password"
                    autoComplete="off"
                    placeholder={`${i18n.t("password")} (*)`}
                    aria-label={i18n.t("password")}
                    dir={i18n.dir(i18n.language)}
                    required
                />
                <StyledInputGroup className="input-group-append">
                    <StyledIconButton
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? (
                            <VisibilityOffIcon />
                        ) : (
                            <VisibilityIcon />
                        )}
                    </StyledIconButton>
                </StyledInputGroup>
            </div>
            <StyledButton
                disabled={VoteStatus.PendingValidation === status}
                type="submit"
                className="btn btn-primary btn-full"
            >
                {`${i18n.t("joinIn")}`}
            </StyledButton>
        </form>
    );
};

const StyledInput = styled(Input)``;

const StyledButton = styled(ValidateButton)``;

const AlertError = styled(Alert)``;

const AlertSuccess = styled(Alert)`
    color: #3c763d;
    background-color: #dff0d8;
`;

const StyledInputGroup = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const StyledIconButton = styled.button`
    position: absolute;
    right: 1px;
    bottom: 5px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #495057;
`;

export default EmailPasswordForm;
