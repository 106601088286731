import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { HTTPResponseError } from "../../../services/errors";
import { enqueueSnackbar } from "notistack";
import { loginEmailPasswordSms } from "components/helper/callApi";
import { VoteStatus } from "types/voteEnum";

import { Alert } from "components/common/Alert";
import { Input } from "components/common/Input";
import { ValidateButton } from "components/common/ValidateButton";

type Props = {
    codeOnline: string;
    email: string;
    password: string;
    phoneNumber: string;
    onSubmitSuccess: (res: Response) => Promise<void>;
    requestVerificationCode: (email: string, password: string) => Promise<void>;
};

const SMSVerificationForm = ({
    codeOnline,
    email,
    password,
    phoneNumber,
    requestVerificationCode,
    onSubmitSuccess,
}: Props) => {
    const { i18n } = useTranslation();

    const [status, setStatus] = useState(VoteStatus.Idle);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [newCodeStatus, setNewCodeStatus] = useState(VoteStatus.Idle);
    const [newCodeErrorMessage, setNewCodeErrorMessage] = useState<
        string | null
    >(null);

    const verificationCodeRef = useRef<HTMLInputElement>(null);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!verificationCodeRef.current) {
            return;
        }

        const verificationCode = verificationCodeRef.current.value;

        let res;

        try {
            res = await loginEmailPasswordSms({
                email: email,
                password: password,
                codeOnline: codeOnline,
                verificationCode: verificationCode,
            });

            if (res.status !== 200) {
                throw new HTTPResponseError(res);
            }
        } catch (error) {
            if (!(error instanceof HTTPResponseError)) {
                setErrorMessage(`${i18n.t("errorUnknown")}`);
                setStatus(VoteStatus.Error);
                return;
            }

            const res = error.response;

            switch (res.status) {
                case 401:
                    setErrorMessage(`${i18n.t("errorCredentialsIncorrect")}`);
                    break;
                default:
                    setErrorMessage(`${i18n.t("errorUnknown")}`);
            }

            setStatus(VoteStatus.Error);
            return;
        }

        try {
            await onSubmitSuccess(res);
        } catch (error) {
            if (!(error instanceof HTTPResponseError)) {
                setErrorMessage(`${i18n.t("errorUnknown")}`);
                setStatus(VoteStatus.Error);
                return;
            }

            const res = error.response;

            switch (res.status) {
                case 401:
                    enqueueSnackbar(`${i18n.t("errorCredentialsIncorrect")}`, {
                        variant: "error",
                        persist: true,
                    });
                    break;
                case 403:
                    enqueueSnackbar(`${i18n.t("error403")}`, {
                        variant: "error",
                        persist: true,
                    });
                    break;
                default:
                    setErrorMessage(`${i18n.t("errorUnknown")}`);
                    setStatus(VoteStatus.Error);
                    break;
            }
        }
    };

    return (
        <>
            <FormWrapper>
                <form onSubmit={handleSubmit}>
                    <StyledParagraph dir={i18n.dir(i18n.language)}>
                        {`${i18n.t("passwordEmailSmsDescription", {
                            phoneNumber,
                        })}`}
                    </StyledParagraph>
                    {errorMessage && <AlertError>{errorMessage}</AlertError>}
                    <div>
                        <StyledInput
                            ref={verificationCodeRef}
                            type="text"
                            placeholder={i18n.t("verificationCode")}
                            required
                            aria-label={i18n.t("verificationCode")}
                            dir={i18n.dir(i18n.language)}
                        />
                    </div>
                    <div>
                        <ValidateStyledButton
                            disabled={VoteStatus.PendingValidation === status}
                            dir={i18n.dir(i18n.language)}
                        >
                            {`${i18n.t("validate")}`}
                        </ValidateStyledButton>
                    </div>
                </form>
            </FormWrapper>
            <WrapperResendCode>
                {VoteStatus.Success === newCodeStatus && (
                    <AlertSuccess dir={i18n.dir(i18n.language)}>{`${i18n.t(
                        "verificationCodeSent"
                    )}`}</AlertSuccess>
                )}
                {newCodeErrorMessage && (
                    <AlertSuccess>{newCodeErrorMessage}</AlertSuccess>
                )}
                <p dir={i18n.dir(i18n.language)}>{`${i18n.t(
                    "noReceivedCode"
                )}`}</p>
                <StyledSendCodeText
                    onClick={() => {
                        setNewCodeStatus(VoteStatus.PendingValidation);
                        setNewCodeErrorMessage(null);

                        requestVerificationCode(email, password)
                            .then(() => {
                                setNewCodeStatus(VoteStatus.Success);
                            })
                            .catch((errorMessageParam) => {
                                setNewCodeErrorMessage(errorMessageParam);
                                setNewCodeStatus(VoteStatus.Error);
                            });
                    }}
                    dir={i18n.dir(i18n.language)}
                >{`${i18n.t("resendSMSCode")}`}</StyledSendCodeText>
            </WrapperResendCode>
        </>
    );
};

const StyledParagraph = styled.p`
    font-size: 15px;
    line-height: 1.5rem;
    margin: 0;
    padding-bottom: 1rem;
`;

const FormWrapper = styled.div`
    padding: 1rem 1.5rem 0 1.5rem;
`;

const AlertError = styled(Alert)``;

const AlertSuccess = styled(Alert)`
    color: #3c763d;
    background-color: #dff0d8;
`;

const WrapperResendCode = styled.div`
    font-size: 12px;
    padding: 1rem 1.5rem 1rem 1.5rem;
    > p {
        margin: 0;
    }
`;

const StyledSendCodeText = styled.div`
    text-decoration: underline;
    cursor: pointer;
`;

const StyledInput = styled(Input)``;

const ValidateStyledButton = styled(ValidateButton)``;

export default SMSVerificationForm;
