import debounce from "lodash.debounce";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useState, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

import { cleanWhitespace } from "components/helper/stringHelper";
import { Input } from "components/common/Input";
import { ValidateButton } from "components/common/ValidateButton";
import {
    getCurrentUser,
    getIsPseudoAvailable,
    getSessionInformation,
    loginFormScenario,
} from "components/helper/callApi";
import { useSession } from "components/helper/sessionContext";
import { useUser } from "components/helper/userContext";

import { LS_AUTH_TOKEN_KEY } from "constants/constants";

import { useHandleErrors } from "./common/handleErrors";
import { UnknownError, NotFoundError } from "services/errors";
import { SessionType } from "types/sessionType";

type Props = {
    codeOnline: string;
    onError: (error: NotFoundError | UnknownError) => void;
};

const Pseudo = ({ codeOnline, onError }: Props) => {
    const { setUser } = useUser();
    const { i18n } = useTranslation();
    const { setSession } = useSession();
    const { enqueueSnackbar } = useSnackbar();
    const [errorPseudoTaken, setErrorPseudoTaken] = useState(false);
    const [errorPseudoEmpty, setErrorPseudoEmpty] = useState(false);
    const fieldName = "pseudo";

    const { handleLoginError, handleCurrentUserError, handleSessionError } =
        useHandleErrors();

    const checkPseudoAvailability = useCallback(
        async (value: string) => {
            const response = await getIsPseudoAvailable(
                codeOnline as string,
                value
            );
            if (response.ok) {
                const responseJson = await response.json();
                setErrorPseudoTaken(Boolean(!responseJson.isPseudoAvailable));
            }
        },
        [codeOnline]
    );

    const debouncedCheckPseudoAvailability = useRef(
        debounce((value: string) => {
            checkPseudoAvailability(value);
        }, 500)
    ).current;

    useEffect(() => {
        return () => {
            debouncedCheckPseudoAvailability.cancel();
        };
    }, [debouncedCheckPseudoAvailability]);

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorPseudoTaken(false);
        const value = cleanWhitespace(e.target.value);
        setErrorPseudoEmpty(!value);
        if (value) {
            debouncedCheckPseudoAvailability(value);
        }
    };

    const onSubmit = async (data: any) => {
        try {
            const pseudo = cleanWhitespace(data.pseudo);
            if (!pseudo) {
                setErrorPseudoEmpty(true);
                return;
            }

            const loginResponse = await loginFormScenario(
                codeOnline,
                "/form/scenario/pseudo",
                { pseudo }
            );

            if (!loginResponse.ok) {
                if (loginResponse.status === 409) {
                    enqueueSnackbar(`${i18n.t("errorMessagePsuedoTaken")}`);
                } else {
                    handleLoginError(loginResponse.status);
                }

                return;
            }

            const response = await loginResponse.json();

            if (response.token) {
                localStorage.setItem(LS_AUTH_TOKEN_KEY, response.token);
            }

            const currentUserResponse = await getCurrentUser();

            if (currentUserResponse.ok) {
                const userData = await currentUserResponse.json();
                setUser(userData);

                const sessionResponse = await getSessionInformation(
                    codeOnline,
                    ["zoomSignature"]
                );
                if (sessionResponse.ok) {
                    const sessionData: SessionType =
                        await sessionResponse.json();
                    setSession(sessionData);
                } else {
                    handleSessionError(sessionResponse.status);
                }
            } else {
                handleCurrentUserError(currentUserResponse.status);
            }
        } catch (error) {
            onError(new UnknownError());
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    return (
        <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <StyledInput
                    {...register(fieldName, {
                        required: true,
                        onChange: handleChange,
                    })}
                    type={"text"}
                    error={Boolean(errors[fieldName])}
                    errorPseudo={Boolean(errorPseudoTaken || errorPseudoEmpty)}
                    placeholder={`${i18n.t("pseudo")}(*)`}
                    aria-invalid={errors[fieldName] ? "true" : "false"}
                    dir={i18n.dir(i18n.language)}
                />
                {(errorPseudoTaken || errorPseudoEmpty) && (
                    <ErrorMessage dir={i18n.dir(i18n.language)}>
                        {errorPseudoTaken
                            ? i18n.t("errorMessagePsuedoTaken")
                            : i18n.t("errorMessagePsuedoMandatory")}
                    </ErrorMessage>
                )}
                <StyledButton type="submit" dir={i18n.dir(i18n.language)}>
                    {i18n.t("joinIn")}
                </StyledButton>
            </form>
        </FormWrapper>
    );
};

const StyledInput = styled(Input)<{
    error: boolean;
    errorPseudo: boolean;
}>`
    &:focus {
        border-color: ${(props) =>
            props.error || props.errorPseudo ? " #dc3545" : null};
        box-shadow: ${(props) =>
            props.error || props.errorPseudo
                ? " 0 0 0 0.2rem rgb(220 53 69 / 25%);"
                : null};
    }
    border-color: ${(props) =>
        props.error || props.errorPseudo ? " #dc3545" : null};
    box-shadow: ${(props) =>
        props.error || props.errorPseudo
            ? " 0 0 0 0.2rem rgb(220 53 69 / 25%);"
            : null};
    margin-bottom: ${(props) => (props.errorPseudo ? "0.6rem" : "2rem")};
`;

const FormWrapper = styled.div`
    padding: 1.5rem;
`;

const ErrorMessage = styled.p<{
    dir: string;
}>`
    color: #dc3545;
    font-size: 0.8rem;
    text-align: ${(props) => (props.dir === "rtl" ? "right" : "left")};
    margin: 0;
    margin-bottom: 0.5rem;
`;

const StyledButton = styled(ValidateButton)``;

export default Pseudo;
