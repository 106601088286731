import styled from "styled-components";
import { useTranslation } from "react-i18next";

type Props = {
    message: string;
};

const BannerInformation = ({ message }: Props) => {
    const { i18n } = useTranslation();

    return (
        <BannerWrapper>
            <p dir={i18n.dir(i18n.language)}>{i18n.t(message)}</p>
        </BannerWrapper>
    );
};

const BannerWrapper = styled.div`
    width: 99%;
    background: #d34742;
    padding: 4px;
    position: absolute;
    > p {
        font-size: 15px;
        margin: 0;
        text-align: center;
        color: #ffff;
    }
`;
export default BannerInformation;
